import React from 'react';
import useQuery from '../useQuery'; // Sesuaikan path jika berbeda
import { Link } from 'react-router-dom';

function Header({ data }) {
  const query = useQuery();
  const race = query.get('race');
  const null_status = query.get('null');
  return (
    <div>
      <div className='bg-white p-3 flex place-content-between shadow shadow-gray-800 '>
        <div className='flex space-x-6'>
          <Link to={`/?race=${race}&null=${null_status}`}>
            <img
              src='/logopmr_putih.webp'
              alt=''
              className='w-auto h-14 my-auto'
            />
          </Link>
          <Link to={`/detail?race=${race}&null=${null_status}`}>
            <button className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded h-11 my-auto'>
              Detail
            </button>
          </Link>
          <Link to={`/nocp?race=${race}&null=${null_status}`}>
            <button className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded h-11 my-auto'>
              No Checkpoint
            </button>
          </Link>
        </div>
        <div className='my-auto'>
          <h1 className='my-auto text-left font-bold text-4xl'>
            UNOFFICIAL RESULT
          </h1>
          {data.date && <h1 className='text-l text-left'>{data.date}</h1>}
        </div>
      </div>
    </div>
  );
}

export default Header;
