import { React, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Header from '../component/Header';
import axios from 'axios';
import TableDetail from '../component/TableDetail';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import useQuery from '../useQuery'; // Sesuaikan path jika berbeda

function Detail() {
  const [dataresult, setDataresult] = useState('');
  const [searchParams] = useSearchParams();
  const query = useQuery();
  const race = query.get('race');
  const null_status = query.get('null');

  // getdata
  const GetDataResult = async () => {
    try {
      const response = await axios.get(
        `https://pickmyrace.frizacahya.com/getdetailresult?race=${race}`
      );
      setDataresult(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleSavePdf = () => {
    const input = document.getElementById('pdf-content');
    html2canvas(input, { scale: 1.0 }).then((canvas) => {
      const imgData = canvas.toDataURL('image/jpg');
      const pdf = new jsPDF();
      const imgWidth = 210; // A4 width in mm
      const pageHeight = 295; // A4 height in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;

      pdf.addImage(imgData, 'JPG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, 'JPG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      pdf.save('document.pdf');
    });
  };
  useEffect(() => {
    GetDataResult();
  }, []);

  return (
    <div>
      <Header data={dataresult} race={race} />
      <button onClick={handleSavePdf} className='btn btn-primary'>
        Save as PDF
      </button>
      {dataresult &&
        dataresult.status !== 'error' &&
        dataresult.status !== 'loading' && (
          <div className='container mx-auto ' id='pdf-content'>
            {Object.keys(dataresult).map((category) => (
              <TableDetail
                key={category}
                title={category}
                data={dataresult[category]}
              />
            ))}
          </div>
        )}
    </div>
  );
}

export default Detail;
