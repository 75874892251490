import React from 'react';

function TableDetail({ title, data }) {
  const columnsToShow = [
    'BIB',
    'POSITION',
    'NAME',
    'GENDER',
    'FINISHTIME',
    'PACE',
    'DETAIL CATEGORY',
    'CP1',
    'CP2',
    'CP3',
    'STATUS',
  ];

  return (
    <div className='flex'>
      {data && (
        <div className='my-6 p-3 border border-slate-400 rounded-lg mx-auto'>
          <h2 className='text-xl font-bold mb-4'>{title}</h2>
          <div className='overflow-x-auto flex'>
            <div className='flex-1 min-w-0'>
              <table className='bg-white border border-gray-300 shadow-md w-full'>
                <thead>
                  <tr>
                    {columnsToShow.map((key) => (
                      <th
                        key={key}
                        className='py-3 px-4 border-b border-gray-300 bg-blue-500 text-center text-sm font-semibold text-white uppercase tracking-wider'
                      >
                        {key}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => (
                    <tr
                      key={index}
                      className={`${
                        index % 2 === 0 ? 'bg-blue-50' : 'bg-white'
                      } hover:bg-blue-100`}
                    >
                      {columnsToShow.map((key, i) => (
                        <td
                          key={i}
                          className='py-2 px-4 border-b border-gray-300 text-sm text-left text-gray-700'
                        >
                          {item[key] !== null ? item[key] : '-'}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default TableDetail;
